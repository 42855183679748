<template>
    <div>
        <section class="innerBanner pt-1">
            <div class="container">
                <div class="row">
                    <div class="col-12">
                        <div class="back-btn mb-4">
                            <router-link to="/">
                                <span class="backIcon">
                                    <span class="icon-arrow"></span>
                                </span>
                                Back
                            </router-link>
                        </div>
                    </div>
                    <div class="col-12">
                        <div class="pageTitle">
                            <h1>Cart</h1>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <div class="pageWrap">
            <div class="container">
                <div class="row">
                    <div class="col-xl-8">
                        <div class="cartContent no-item-cart" v-if="cartQuantity === 0 ">
                            <span class="icon-empty"></span>
                            <p>There is no any items on cart</p>
                            <router-link to="" class="brand-color">Start Learning</router-link>
                        </div> 
                        <div v-else class="cartContent">
                            <div class="cartList">
                                <ul>
                                    <li v-for="(item,i) in cartItems" :key="i" >
                                        <router-link to="">
                                            <div class="itemImage">
                                                <img :src="item.thumbnail">
                                            </div>
                                            <div class="itemContent">
                                                <div>
                                                    <h3> {{ item.title }} </h3>
                                                    <p>Type: <span class="brand-color"> {{item.item_type}} </span></p>
                                                    <template v-if="item.is_offer">
                                                        <span class="price" ><del> {{item.display_price}} </del></span>
                                                        <span class="price"> {{ item.display_offer_price }} </span>
                                                    </template>
                                                    <template v-else>
                                                        <span class="price"> {{ item.display_price }} </span>
                                                    </template>
                                                </div>
                                                <div class="cartRemove" @click="callRemoveCartPromise(item)" >
                                                    <span class="icon-trash"></span>
                                                </div>
                                            </div>
                                        </router-link>
                                    </li>
                                </ul>
                            </div>
                        </div> 

                    </div>
                    <div class="col-xl-4">
                        <div class="cartAction">
                            <div class="totalAmount">
                                <strong>Total:</strong>
                                <span class="brand-color"> {{ currency }} {{cartTotal}} </span>
                            </div>
                            <div class="checkoutOption">
                                <button class="univ-btn w-100" @click="redirctCheckout"> Checkout </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import store from "@/store"
import router from "@/router"
import { mapGetters, mapActions } from "vuex"
export default{
    name: 'MobileCart',
    data: () => ({
        cartScroll: {
            vuescroll: {
                mode: "native",
                wheelScrollDuration: "700",
            },
            scrollPanel: {
                maxHeight: 600,
            },
            bar: {
                background: "#ddd",
            },
        },
        userId: store.getters.user.id,
        isAuthenticated: store.getters.authenticated,
        currency: store.getters.currency,
    }),
    computed: {
        ...mapGetters(["cartItems", "cartTotal", "cartQuantity"]),
    },
    created() {
        let app = this;
        if(app.isAuthenticated){
            app.$store.dispatch("getCartItems",app.userId);
        }
    },
    methods: {
        toggleSidebar() {
            let app = this;
            app.$root.$emit("cart-toggle", false);
        },
        ...mapActions(["removeCartItem"]),
        callRemoveCartPromise(item){
            let app = this;
            app.removeCartItem({id:item.cartId,item_id:item.item_id,type:item.item_type}).then(() => {
                app.$root.$emit("cart-item-removed", true);
            }).catch(()=>{});
        },
        redirctCheckout(){
            let app = this;
            router.push({ name: "CartCheckout" })
            app.$root.$emit("cart-toggle", false);
        }
    }
}
</script>